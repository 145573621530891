<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Enrolment years</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Enrolment years
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn  @click="createEnrolmentYear()" class="mt-4 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add enrolment year
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
<!--            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="4">
                <v-select label="Select year" v-model="search.year" item-value="year" item-text="year" outlined dense :items="unique_enrolment_years" ></v-select>
              </v-col>
                <v-btn @click.prevent="searchEnrolmentYear()" class="mt-4 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
              </div>
            </div>-->
            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table">
                <thead>
                <tr class="px-3">
                  <th class="px-3">Enrolment years</th>
<!--                  <th class="pr-3 text-center">Options</th>-->
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr  v-for="item in enrolment_years">
                    <td class="px-2">
                      <a  class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{item.year }}
                      </a>
                    </td>
<!--                    <td class="pr-0 text-center">-->
<!--                      <template>-->
<!--                        <b-dropdown-->
<!--                            size="sm"-->
<!--                            variant="link"-->
<!--                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"-->
<!--                            no-caret-->
<!--                            right-->
<!--                            no-flip-->
<!--                        >-->
<!--                          <template v-slot:button-content>-->
<!--                            <i class="ki ki-bold-more-hor"></i>-->
<!--                          </template>-->
<!--                          &lt;!&ndash;begin::Navigation&ndash;&gt;-->
<!--                          <div class="navi navi-hover min-w-md-250px">-->
<!--                            <b-dropdown-text tag="div" class="navi-item">-->
<!--                              <a  @click="deleteEnrolmentYear(item.id)" class="navi-link">-->
<!--                                <span class="navi-icon">-->
<!--                                    <i class="fas fa-trash"></i>-->
<!--                                </span>-->
<!--                                <span class="navi-text">Delete</span>-->
<!--                              </a>-->
<!--                            </b-dropdown-text>-->
<!--                          </div>-->
<!--                          &lt;!&ndash;end::Navigation&ndash;&gt;-->
<!--                        </b-dropdown>-->
<!--                      </template>-->
<!--                    </td>-->
                  </tr>
                  <tr v-if="enrolment_years.length == 0">
                    <td colspan="2" class="text-center">
                      <strong>No items added</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination :disabled="isLoading"
                  v-if="enrolment_years.length > 0"
                  class="pull-right mt-7"
                  @input="getAllEnrolmentYear"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
        </div>
      </div>
    </div>
      <create-or-update ref="create-or-update" @refresh="getAllEnrolmentYear"></create-or-update>
    </div>
  </v-app>
</template>
<script>
import EnrolmentYearService from "@/services/setting/enrolment-year/EnrolmentYearService";
const enrolmentYear=new EnrolmentYearService();
import CreateOrUpdate from './CreateOrUpdate';

export default {
  components:{
    CreateOrUpdate,
  },
  data() {
    return {
      search: {
        year: '',
      },
      enrolment_years:[],
      unique_enrolment_years:[],
      total: null,
      perPage: null,
      page: null,
      isLoading:false,
    }
  },
  methods:{
    getAllEnrolmentYear(){
      this.isLoading=true;
      enrolmentYear
          .paginate(this.search, this.page)
          .then(response => {
            this.enrolment_years=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.isLoading=false;
          })
          .catch((err) => {

          });
    },
    searchEnrolmentYear(){
      this.getAllEnrolmentYear()
    },
    resetSearch(){
      this.search={},
          this.getAllEnrolmentYear();
    },
    getUniqueEnrolmentYear(){
      enrolmentYear
      .uniqueEnrolmentYear()
      .then(response => {
          this.unique_enrolment_years=response.data.data
      })
    },
    createEnrolmentYear(){
      this.$refs['create-or-update'].createEnrolmentYear();
    },
    editEnrolmentYear(item) {
      this.$refs['create-or-update'].editEnrolmentYear(item);
    },
    deleteEnrolmentYear(item){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            enrolmentYear
                .delete(item)
                .then((response) => {
                  this.getAllEnrolmentYear();
                  this.$snotify.success("Enrolment year deleted");
                })
                .catch((err) => {
                  this.isLoading = false;
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
  },
  computed:{

  },
  mounted() {
    this.getAllEnrolmentYear();
    this.getUniqueEnrolmentYear();
  }
}
</script>