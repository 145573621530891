<template>
  <v-dialog
      v-model="dialog"
      max-width="800px"
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>{{ title }} enrolment year</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" sm="12" md="12">

              <v-autocomplete
                  v-model="enrolment_year.year"
                  :items="years"
                  outlined :error="$v.enrolment_year.year.$error"
                  dense :menu-props="{ bottom: true, offsetY: true, maxHeight: 290 }">
                <template v-slot:label>
                  Enrolment year <span class="text-danger">*</span>
                </template>
                <template v-slot:no-data>
                  <div class="no-data-auto-complete text-center">No data available </div>
                </template>
              </v-autocomplete>
              <span class="text-danger" v-if="$v.enrolment_year.year.$error">This information is required</span>
              <span class="text-danger" v-if="errors.year" >{{errors.year[0]}}</span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            text
            x-large
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            x-large
            dark
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>


      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import EnrolmentYearService from "@/services/setting/enrolment-year/EnrolmentYearService";
import {required,numeric,maxLength,minLength,maxValue,minValue} from "vuelidate/lib/validators";
import SettingService from "@/services/setting/SettingService";

const setting=new SettingService;
const enrolmentYear=new EnrolmentYearService();
export default {
  validations:{
    enrolment_year:{
      year:{
        required,
     },
    },
  },
  data() {
    return {
      dialog: false,
      edit: false,
      loading: false,
      title:'',
      enrolment_year:{
        year:'',
      },
      years:[],
      errors:[]
,    }
  },
  methods:{
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
      this.$emit('refresh');
    },
    openDialog() {
      this.dialog = true;
      this.$v.$reset();

    },
    resetForm(){
      this.enrolment_year={};
    },
    createEnrolmentYear() {
      this.resetForm();
      this.openDialog();
      this.edit = false;
      this.title = "Add";
      this.getAllYears();
    },
    editEnrolmentYear(item) {
      this.edit = true;
      this.enrolment_year = item;
      this.enrolment_year.year=parseInt(this.enrolment_year.year);
      this.openDialog();
      this.title = "Edit";
    },
    getAllYears(){
        setting
        .getAllYears()
        .then(response=>{
          this.years=response.data.years;
        })
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function () {
      this.loading = true;
      enrolmentYear
          .create(this.enrolment_year)
          .then(response => {
            this.$snotify.success("Enrolment year added");
            this.closeDialog();
            this.resetForm();
            this.errors=[];
            this.loading = false;
            this.$emit('refresh');
          })
          .catch(error => {
            this.errors = error.errors;
          })
    },
    update: function () {
      this.loading = true;
      enrolmentYear
          .update(this.enrolment_year.id, this.enrolment_year)
          .then(response => {
            this.$snotify.success("Enrolment year updated");
            this.closeDialog();
            this.resetForm();
            this.errors=[];
            this.loading = false;
            this.$emit('refresh');
          })
          .catch(error => {
            this.errors = error.errors;
          })
          .catch((err) => {
            this.loading = false;
            this.errors = err.response.data.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
  },

  mounted() {

  }
}
</script>